import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article, data }) => {
  const { pathname } = useLocation()

  const {
    siteTitle,
    siteDescription,
    siteUrl,
    siteImage,
    author,
  } = data.siteMetadata

  const seo = {
    title: title ? `${title} - ${siteTitle}` : siteTitle,
    description: description || siteDescription,
    image: `${siteUrl}${image || siteImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title} htmlAttributes={{ lang: 'en' }}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      { author && (<meta name="author" content={author} />) }

      // facebook
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      { siteTitle && (<meta property="og:site_name" content={siteTitle} />) }
      {seo.image && <meta property="og:image" content={seo.image} />}
      
      // twitter
      <meta name="twitter:card" content="summary_large_image" />
      { siteTitle && (<meta property="twitter:site" content={siteTitle} />) }
      {author && (
        <meta name="twitter:creator" content={author} />
      )}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      { seo.url && <meta property="twitter:url" content={seo.url} /> }
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
