import { Link } from 'gatsby'
import React from 'react'

const Footer = ({ siteTitle }) => (
  <footer className='bg-light pb-4'>
    <div class="container">
      <div class="row">
        <nav className='navbar navbar-expand-lg navbar-light container col text-center'>
          <ul className='navbar-nav w-100 d-flex justify-content-around'>
            <li className='nav-item'>
              <Link to='/' className='nav-link text-dark'>
                Home
              </Link>
            </li>
            
            <li className='nav-item'>
              <Link to='/findings' className='nav-link text-dark'>
                Findings
              </Link>
            </li>
            
            <li className='nav-item'>
              <Link to='/compare' className='nav-link text-dark'>
                Compare workers
              </Link>
            </li>
            
            <li className='nav-item'>
              <Link to='/about' className='nav-link text-dark'>
                About
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <hr className='w-25'/>
      <div class="row">
        <section class="col pt-3 text-center text-dark">
          <p class='h6'><small><strong>CONTACT US</strong></small></p>
          <small>
            <address>
              <a href="mailto:illinoislabored@illinois.edu" className='dark-link'>illinoislabored@illinois.edu</a>
              <br />
              <a href="tel:217-333-0980" className='dark-link'>217-333-0980</a>
              <br />
              <p>504 E. Armory Ave. <br />Champaign, IL 61822</p>
            </address>
          </small>
        </section>
        <div className='text-center text-dark d-flex align-items-center justify-content-center col'>
          <small>
            <p className='mb-0'>&copy; Copyright {new Date().getFullYear()}</p>
            <p className='mb-0'>Project for Middle Class Renewal</p>
            <p className='mb-0'>School of Labor and Employment Relations</p>
            <p className='mb-0'>University of Illinois at Urbana-Champaign</p>
          </small>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
