/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css'

import '../styles/global.css'
import Header from "../components/Header"
import Footer from '../components/Footer'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteTitle
          siteDescription
          author
          siteUrl
          siteImage
        }
      }
    }
  `)

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.siteTitle} />
      <main>{children}</main>
      <Footer siteTitle={data.site.siteMetadata.siteTitle} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
