import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'


const Header = ({ siteTitle }) => (
  <header>
    <Navbar bg='light' expand='lg' className='row d-flex flex-row justify-content-center px-5'>
      <Nav className='col-md-8 text-center'>
        <Link to="/">
          <span className='h3 text-dark'>{siteTitle}</span>
        </Link>
      </Nav>
      
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='d-flex justify-content-around ml-auto text-center'>
          <Nav>
            <Link to='/' className='nav-link text-dark'>
              Home
            </Link>
          </Nav>
          <Nav>
            <Link to='/findings' className='nav-link text-dark'>
              Findings
            </Link>
          </Nav>
          <Nav>
            <Link to='/compare' className='nav-link text-dark'>
              Compare workers
            </Link>
          </Nav>
          <Nav>
            <Link to='/about' className='nav-link text-dark'>
              About
            </Link>
          </Nav>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
